<template>

  <v-dialog
    v-model="show"
    persistent
    :max-width="WIDTH">
    <v-card>
        <v-toolbar class="mid-font" color="primary" dense dark >
            <v-icon left >mdi-account</v-icon><span class="va-underline">{{state=='new'?'New':'Edit'}} Voter's Info</span>
            <v-spacer/>
            <v-icon  @click="emitToParent('close')">mdi-close</v-icon>
          </v-toolbar>
        <v-card-text>
          <v-row>
             <v-col :cols="state=='update'?'6':'12'">
             <v-form
                ref="form"
                class="mt-4"
                autocomplete="off"
                v-model="valid"
                lazy-validation
              >
              <v-row>
                <v-col cols="12">
                  
               <v-text-field
                 v-model="voter.lastname"
                  label="Last Name *"
                  outlined
                  dense
                  :rules="textRules"
                required
                ></v-text-field>

                <v-text-field
                 outlined
                  dense
                 v-model="voter.firstname"
                  label="First Name"
                  :rules="textRules"
                required
                ></v-text-field>

                <v-text-field
                 outlined
                  dense
                 v-model="voter.middlename"
                  label="Middle Name"
                  :rules="textRules"
                required
                ></v-text-field>

                <v-text-field
                 outlined
                  dense
                 v-model="voter.ext"
                  label="Ext"
                  :rules="textRules"
                required
                ></v-text-field>

                <v-autocomplete
                  v-model="voter.prec"
                  item-text="Precinct"
                  return-object
                   :rules="textRules"
                required
                  :items="precincts"
                  outlined
                    dense
                  label="Select Precinct"
                  ></v-autocomplete>
                <!-- <v-autocomplete
                  v-model="voter.status"
                  item-text="Assestment"
                  return-object
                   :rules="textRules"
                required
                  :items="['Verified', 'Unverified']"
                  outlined
                    dense
                  label="Select Agent Upline"
                  ></v-autocomplete> -->

                </v-col>
               
              </v-row>
             </v-form>
            </v-col>
            <v-col color="6" v-if="state=='update'">
                <div class="pa-2 mt-4 text-h6">Claimed Benefits:</div>
                <v-divider/>
                <div class="pa-1 text-body-2"  v-for="(item, index) in claims" :key="index">{{index+1}}. {{item.name}} - <em>{{$moment(item.event_dt).format('MMM DD, YYYY hh:mm A')}}</em></div>
            </v-col>
            </v-row>
        </v-card-text>
        <v-card-actions>
                <v-btn color="warning" small rounded text @click="emitToParent('close')">CLOSE</v-btn>
            <v-spacer></v-spacer>
            <v-btn  small color="success"  depressed  @click="validate()">{{BTN_TEXT}}</v-btn>
        </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    show: {
        type: Boolean,
        default: false
    },
    voter_id: {
        type: Number,
        default:null
    },
    selected: {
        type: Object,
        default: ()=>{}
    },
    state: {
        type: String,
        default: "new"
    }
  },
  data: () => ({
      valid: true,
      newpassword:'',
      voter:{},
      claims: {},
      precincts:[
"0001A",
"0001B",
"0001C",
"0001D",
"0002A",
"0002B",
"0003A",
"0003B",
"0003C",
"0004A",
"0004B",
"0004C",
"0004D",
"0005A",
"0005B",
"0005C",
"0005D",
"0006A",
"0006B",
"0006C",
"0007A",
"0007B",
"0007C",
"0008A",
"0008C",
"0008D",
"0008E",
"0009A",
"0009B",
"0009C",
"0010A",
"0010B",
"0010C",
"0011A",
"0011B",
"0011C",
"0012A",
"0012B",
"0012C",
"0013A",
"0013B",
"0013C"
],
      show1: false,
      textRules: [
        v => !!v || 'Field is required'
      ],
  }),
  computed: {
    WIDTH() {
      if(this.state == 'new')
            return 400
         else 
            return 800
    },
      BTN_TEXT() {
          if(this.state == 'new')
            return "ADD"
         else 
            return "SAVE"
      },
      
  },
  watch: {
    show (v) {
      if (!v) {
        this.newpassword = ''
        this.show1=false
      
      } else {
       // this.user.password =''
       this.voter = this.selected
        this.getAccount()
        if(this.state == 'update') {
          this.myClaims()
        }
      }
    }
  },
  methods: {
      validate () {
        if (this.$refs.form.validate()) {
          this.emitToParent(this.state)
          this.show1 = false
        }
      },
    myClaims(){
    this.$http.post("claims/get",{status:"Claimed", id: this.voter.id}).then(response => {
      response.data.claims !=null?this.claims = response.data.claims:this.claims = []
          }).catch(e => {
            console.log(e.data)
          })
  },
      getAccount(){
        console.log({status:"by_id", id: this.voter.id})
          this.$http.post("voter/view", {status:"by_id", id: this.voter.id}).then(response => {
           
            response.data.status? this.voter = response.data.record:this.voter={} 
        }).catch(e => {
          console.log(e)
        })
      },
       ChangePassword: function() {
      
     },
    emitToParent (action) {
      this.$emit('DialogEvent', {action: action, voter:this.voter})
    }

  }
}
</script>
